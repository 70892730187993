import { CALL_API } from '../middlewares/customMiddleware';
import { passUrlQueryParams } from '../../common/utils';
import { SLS_FE_CONFIG_API_URL } from '../../common/settings/ApiUrlSettings';

export const FRONTEND_CONFIGURATION_REQUEST = 'FRONTEND_CONFIGURATION_REQUEST';
export const FRONTEND_CONFIGURATION_SUCCESS = 'FRONTEND_CONFIGURATION_SUCCESS';
export const FRONTEND_CONFIGURATION_FAILURE = 'FRONTEND_CONFIGURATION_FAILURE';
export const FRONTEND_URL = '/frontends';

export const getFrontendConfiguration = params => dispatch => {
  const fullFrontendUrl = `${SLS_FE_CONFIG_API_URL}${passUrlQueryParams(FRONTEND_URL, params)}`;
  return dispatch({
    [CALL_API]: {
      types: [
        FRONTEND_CONFIGURATION_REQUEST,
        FRONTEND_CONFIGURATION_SUCCESS,
        FRONTEND_CONFIGURATION_FAILURE
      ],
      endpoint: fullFrontendUrl,
      method: 'GET'
    }
  });
};

export const UPDATE_FRONTEND_CONFIGURATION = 'UPDATE_FRONTEND_CONFIGURATION';
export const updateFrontendConfiguration = data => ({
  type: UPDATE_FRONTEND_CONFIGURATION,
  payload: data
});
