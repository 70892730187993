import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import $ from 'jquery';
import 'dom4'; //support dom4 in IE
import './assets/stylesheets/global.scss';
import routes from './routes';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.token = null;
    this.clientHeight = 200;
    this.rootHeight = 0;
    this.content = null;
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleCloseTabOrBrowserWhenSubmittingContactForm);
  }

  componentWillReceiveProps(nextProps) {
    const { frontendConfiguration } = this.props;
    if (
      frontendConfiguration !== nextProps.frontendConfiguration &&
      frontendConfiguration.token !== nextProps.frontendConfiguration.token
    ) {
      this.token = nextProps.frontendConfiguration.token;
      this.content = document.getElementById('root');
      if (this.content) {
        let initHeight = setInterval(() => {
          if (this.content.scrollHeight >= 184) {
            this.resizeIframe();
            clearInterval(initHeight);
          }
        }, 70);
        let resizeTimeoutId;
        window.addEventListener('resize', () => {
          clearTimeout(resizeTimeoutId);
          resizeTimeoutId = setTimeout(() => this.resizeIframe(), 100);
        });
        let mutationObserverTimeoutId;
        const config = { attributes: true, childList: true, subtree: true };
        const observer = new MutationObserver(() => {
          clearTimeout(mutationObserverTimeoutId);
          mutationObserverTimeoutId = setTimeout(() => this.resizeIframe(), 100);
        });
        observer.observe(document.body, config);
      }
    }
    if (
      frontendConfiguration !== nextProps.frontendConfiguration &&
      (frontendConfiguration.customCode !== nextProps.frontendConfiguration.customCode ||
        frontendConfiguration.newCustomCode !== nextProps.frontendConfiguration.newCustomCode)
    ) {
      const { customCode, newCustomCode } = nextProps.frontendConfiguration;
      $('head').append(customCode);
      $('body').append(newCustomCode);
    }
  }

  componentDidUpdate(prevProps) {
    const { isSubmittingContactForm, isUploading } = this.props;
    if (
      prevProps.isSubmittingContactForm !== isSubmittingContactForm ||
      prevProps.isUploading !== isUploading
    ) {
      const message = { isProcessing: isUploading || isSubmittingContactForm };
      window.parent.postMessage(message, '*');
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      'beforeunload',
      this.handleCloseTabOrBrowserWhenSubmittingContactForm
    );
  }

  handleCloseTabOrBrowserWhenSubmittingContactForm = event => {
    const { isSubmittingContactForm, isUploading } = this.props;
    if (isSubmittingContactForm || isUploading) {
      event.preventDefault();
      event.returnValue =
        'The attachment uploading is on-going. Are you sure you want to close browser?';
      return 'The attachment uploading is on-going. Are you sure you want to close browser?';
    }
  };

  resizeIframe = () => {
    const contentHeight = this.calculateHeight();
    if (this.rootHeight !== contentHeight) {
      const message = { token: this.token, height: contentHeight };
      window.parent.postMessage(message, '*');
      this.rootHeight = contentHeight;
    }
  };

  calculateHeight = () => {
    if (this.content.clientHeight === 0) {
      return this.rootHeight;
    }
    return this.content.clientHeight;
  };

  render() {
    return (
      <Router>
        {routes.map(route => (
          <Route key={route.path} {...route} />
        ))}
      </Router>
    );
  }
}

App.propTypes = {
  frontendConfiguration: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isSubmittingContactForm: PropTypes.bool.isRequired,
  isUploading: PropTypes.bool.isRequired
};

App.defaultProps = {
  frontendConfiguration: {}
};

const mapStateToProps = state => ({
  frontendConfiguration: state.frontendConfiguration,
  isSubmittingContactForm: state.contactFormData.isSubmittingContactForm,
  isUploading: state.uploadFileStatus.isUploading
});

export default connect(mapStateToProps)(App);
